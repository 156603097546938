<template>
    <div class="container">
        <Pbanner :pbanner="pbanner"/>
        <section class="party__new">
            <div class="party__new--item" v-for="(item, index) in partyData.list" :key="index">
                <div class="party__new--padd">
                    <div class="party__new--title">
                        <div class="party__title--wrap inner">
                            <div class="party__tt">
                                <i></i><strong>{{item.name}}</strong>
                            </div>
                            <div class="party__more">
                                <router-link :to="'/about/partylist/'+item.catid"></router-link>
                            </div>
                        </div>
                    </div>
                    <div class="party__new--wr inner">
                        <div class="party__swiper" v-if="item.imglist.length > 0">
                            <swiper :options="swiperOption" ref="mySwiper">
                                <swiper-slide class="partys-items" v-for="partyImg in item.imglist" :key="partyImg.cid">
                                    <router-link :to="'/party/'+partyImg.cid">
                                        <figure><img :src="partyImg.thumb" :alt="partyImg.title"/></figure>
                                        <strong>{{partyImg.title}}</strong>
                                    </router-link>
                                </swiper-slide>
                                <div class="swiper-pagination" slot="pagination"></div>
                            </swiper>
                        </div>
                        <div class="party__fixs" v-if="item.list.length > 0">
                            <ul class="party__listd">
                                <li v-for="partyList in item.list" :key="partyList.cid">
                                    <router-link :to="'/party/'+partyList.cid">
                                        <strong>{{partyList.title}}</strong>
                                        <time>{{partyList.create_time}}</time>
                                    </router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import Pbanner from '../../components/Pbanner';
export default {
    data(){
        return {
            partyData: {},
            pbanner:"",
            swiperOption: {
                loop: true,
                speed:1000,
                parallax : true,
                spaceBetween : 0,
                autoplay: {
                    delay: 4000,
                    stopOnLastSlide: false,
                    disableOnInteraction: false
                },
                observeParents:false,   
                observer:true,
                // 显示分页
                pagination: {
                    el: ".swiper-pagination",
                    clickable: true,
                }
            }
        }   
    },
    components: {
        Pbanner,
    },
    methods: {
        async getParty(){
            const {data: res} = await this.$http.post("getPartyBuilding");
            if(res.status == 1){
                this.partyData = res.data
                this.pbanner = res.data.catinfo.thumb
                console.log(this.partyData)
            }else{
                this.$message.error('数据请求失败');
            }
        }
    },
    mounted(){
        this.getParty();
    },
    computed: {
        swiper() {
            return this.$refs.mySwiper.swiper;
        },
    },
}
</script>

<style lang="less">
</style>