<template>
    <div class="pabbner-wraaper">
        <div class="pbanner">
            <figure><img :src="pbanner" alt=""/></figure>
            <slot name="default"></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        pbanner: String,
    },
    data() {
        return {
        }
    },
}
</script>

<style lang="less">
.pbanner{
    position: relative;
    height: 1;
    overflow: hidden;
    padding-bottom: 24.583%;
    img{
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

</style>