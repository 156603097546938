<template>
   <ul class="leval-menu-list">
        <li :class="index==active?'active':''" v-for="(item,index) in menuLeval" :key="item.id" @click="classToggle(index)" >
            
            <router-link v-if="$route.path != '/about/party/' && $route.path != '/about/partyact/' && $route.path != '/about/party' && $route.path != '/about/partyact'" active-class="active" :to="`${fristmenu}${item.path}`" >{{item.name}}</router-link>
            <router-link v-else active-class="active" :to="`${fristmenu}${item.url}`" >{{item.name}}</router-link>
        </li>
    </ul>
</template>

<script>
export default {
    props: {
        menuNumber: Number,
    },
    data() {
        return {
            fristmenu:[],
            menuLeval: [],
            active: 0
        }
    },
    methods: {
        async getNavs(){
            const {data: res} = await this.$http.post("getCate");
            this.fristmenu = res.data[this.menuNumber].path;
           
            if(this.$route.path == '/about/party/' || this.$route.path == '/about/partyact/' || this.$route.path == '/about/party' || this.$route.path == '/about/partyact'){
               let arr=res.data[this.menuNumber].subItems
            //    console.log(arr)
            console.log(arr)
               arr.forEach((element, i) => {
                    if(element.id == 10){
                        this.menuLeval = element.child
                    }
                });
            }else{
                this.menuLeval = res.data[this.menuNumber].subItems;
            }
        },
        classToggle(index){
          this.active = index;
        }
    },
    mounted() {
        this.getNavs();
    }
}
</script>

<style lang="less">
    
</style>